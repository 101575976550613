import { useMemo, useState } from 'react';
import {
  IAssessmentResource,
  ILineResource,
  IMaintenanceResource,
  ISeedInventoryResource,
} from '../../entities/farms.entities';
import { ITaskData } from '../../entities/task.entities';
import AssessmentCell from './AssessmentCell';
import HarvestCell from './HarvestCell';
import InventorySeedCell from './InventorySeedCell';
import MaintenanceCell from './MaintenanceCell';
import RequireAssessmentCell from './RequireAssessmentCell';
import SeedingCell from './SeedingCell';
import TaskCell from './TaskCell';
import {
  IHarvestResource,
  ISeedingResource,
} from '../../entities/growing.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { IBoatRefuel } from '../../entities/boat.entities';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';

interface RefuelProps {
  lang: any;
  data: IBoatRefuel;
}

const RefuelCell = ({ lang, data }: RefuelProps) => (
  <div className='--entity --refuel'>
    <div className='--header'>
      <span>{translate(lang, 'refuel')}</span>
    </div>
    <div className='--title'>
      <div className='mb-7'>{data.boat.name}</div>
      {data.fuel_price !== null ? (
        <div>{`${data.fuel_amount}L / $${data.fuel_price}`}</div>
      ) : (
        <div>{`${data.fuel_amount}L`}</div>
      )}
      <div className='mt-7'>
        {`${moment(toMillisecond(data.start_time)).format(
          'hh:mm A',
        )} - ${moment(toMillisecond(data.finish_time)).format('hh:mm A')}`}
      </div>
      {!data.location !== null && <div className='mt-7'>{data.location}</div>}
    </div>
  </div>
);

interface ICell {
  seedings: ISeedingResource[];
  assessments: IAssessmentResource[];
  maintenances: IMaintenanceResource[];
  inventory_seeds: ISeedInventoryResource[];
  harvests: IHarvestResource[];
  tasks: ITaskData[];
  req_assess_seeds: ILineResource[];
  boat_refuels: IBoatRefuel[];
}

interface Props {
  cellData: ICell;
  onShowTask: (data: ITaskData) => void;
  onCompleteTask: (data: ITaskData) => void;
  onEditTask: (data: ITaskData) => void;
  onDeleteTask: (data: ITaskData) => void;
  onShowSeed: (data: any) => void;
  onShowAssessment: (d: any) => void;
  onShowMaintenance: (d: any) => void;
  onShowFloats: (d: any) => void;
  onShowReqAssess: (d: any) => void;
  onShowHarvest: (d: any) => void;
  perPage?: number;
}

const CalendarCell = ({
  cellData,
  onShowTask,
  onCompleteTask,
  onEditTask,
  onDeleteTask,
  onShowSeed,
  onShowAssessment,
  onShowMaintenance,
  onShowFloats,
  onShowReqAssess,
  onShowHarvest,
  perPage = 5,
}: Props) => {
  const lang = useSelector(selectLang);

  const entities: Array<{ type: string; data: any }> = [
    ...cellData.req_assess_seeds.map(x => ({ type: 'REQ', data: x })),
    ...cellData.tasks.map(x => ({ type: 'TASK', data: x })),
    ...cellData.seedings.map(x => ({ type: 'SEED', data: x })),
    ...cellData.assessments.map(x => ({ type: 'ASS', data: x })),
    ...cellData.maintenances.map(x => ({ type: 'MNT', data: x })),
    ...cellData.inventory_seeds.map(x => ({ type: 'IVT', data: x })),
    ...cellData.harvests.map(x => ({ type: 'HAR', data: x })),
    ...cellData.boat_refuels.map(x => ({ type: 'REFUEL', data: x })),
  ];

  const pageSize = useMemo(
    () => Math.ceil(entities.length / perPage),
    [entities],
  );
  const [curPage, setCurPage] = useState(1);

  const handlePageChange = (btn: 'next' | 'prev') => {
    if (btn === 'next' && curPage < pageSize) {
      setCurPage(curPage + 1);
    } else if (btn === 'prev' && curPage > 1) {
      setCurPage(curPage - 1);
    }
  };
  const entityData = useMemo(
    () =>
      entities.filter(
        (x, i) => i >= (curPage - 1) * perPage && i < curPage * perPage,
      ),
    [perPage, curPage, entities],
  );

  return (
    <div className='cell-wrap'>
      <div className='--entities'>
        {entityData.map((item, idx) =>
          item.type === 'TASK' ? (
            <TaskCell
              key={idx}
              task={item.data}
              onShowTask={onShowTask}
              onCompleteTask={onCompleteTask}
              onEditTask={onEditTask}
              onDeleteTask={onDeleteTask}
            />
          ) : item.type === 'SEED' ? (
            <SeedingCell
              key={idx}
              seedData={item.data}
              onShowSeed={onShowSeed}
            />
          ) : item.type === 'ASS' ? (
            <AssessmentCell
              key={idx}
              assessData={item.data}
              onShowAssessment={onShowAssessment}
            />
          ) : item.type === 'MNT' ? (
            <MaintenanceCell
              key={idx}
              mntData={item.data}
              onShowMaintenance={onShowMaintenance}
            />
          ) : item.type === 'IVT' ? (
            <InventorySeedCell
              key={idx}
              floatsData={item.data}
              onShowFloats={onShowFloats}
            />
          ) : item.type === 'HAR' ? (
            <HarvestCell
              key={idx}
              harvestData={item.data}
              onClickCell={onShowHarvest}
            />
          ) : item.type === 'REQ' ? (
            <RequireAssessmentCell
              key={idx}
              reqLine={item.data}
              onClickAssessment={onShowReqAssess}
            />
          ) : item.type === 'REFUEL' ? (
            <RefuelCell key={idx} data={item.data} lang={lang} />
          ) : (
            <></>
          ),
        )}
      </div>
      <div className='--buttons'>
        {entities.length > perPage && (
          <div className='paginator'>
            {curPage > 1 && (
              <button
                className='prev-btn'
                onClick={handlePageChange.bind(this, 'prev')}
              >
                <span>{translate(lang, 'Previous')}</span>
              </button>
            )}
            <div className='page-num'>
              {curPage}/{pageSize}
            </div>
            {curPage < pageSize && (
              <button
                className='prev-btn'
                onClick={handlePageChange.bind(this, 'next')}
              >
                <span>{translate(lang, 'Next')}</span>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarCell;
